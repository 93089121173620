let authInfo = {
  reset: {
    no_access: [],
    limited_access: [],
  },
  HeaderMenu: {
    full_access: [
      "application_user",
      // "account_admin",
      "platform_admin",
      "platform_user",
      "application_admin",
      "department_manager_clinical",
      // "department_manager_sales",
      "department_manager_billing",
      "department_user_billing",
      "department_user_sales",
      "department_user_clinical",
      "multi_department_manager_billing",
      "multi_department_manager_sales",
      "multi_department_manager_clinical",
    ],
    no_access: ["department_manager_sales", "account_admin"],
    limited_access: [],
  },

  HomeScreen: {
    page_level: {
      full_access: [
        "platform_admin",
        "platform_user",
        "application_admin",
        "application_user",
        "account_admin",
        "department_manager_clinical",
        "department_manager_sales",
        "department_manager_billing",
        "department_user_billing",
        "department_user_sales",
        "department_user_clinical",
        "multi_department_manager_billing",
        "multi_department_manager_sales",
        "multi_department_manager_clinical",
      ],
      no_access: [],
      limited_access: [],
    },
    homescreen_admin: {
      full_access: [
        // "application_user",
        // "account_admin",
        "platform_admin",
        "platform_user",
        "application_admin",
        //  "department_manager_clinical",
        "department_manager_sales",
        "department_manager_billing",
        // "department_user_billing",
        // "department_user_sales",
        // "department_user_clinical",
        "multi_department_manager_billing",
        "multi_department_manager_sales",
      ],
      no_access: [
        "application_user",
        "account_admin",
        "department_manager_clinical",
        "department_user_billing",
        "department_user_sales",
        "department_user_clinical",
        "multi_department_manager_clinical",
      ],
      limited_access: [],
    },
    homescreen_clinical: {
      full_access: [
        "platform_admin",
        "platform_user",
        "application_admin",
        "application_user",
        //  "account_admin",
        "department_manager_clinical",
        "department_manager_sales",
        // "department_manager_billing",
        // "department_user_billing",
        // "department_user_sales",
        "department_user_clinical",
        "multi_department_manager_sales",
        "multi_department_manager_clinical",
      ],
      no_access: [
        "account_admin",
        "department_manager_billing",
        "multi_department_manager_billing",
        "department_user_billing",
        "department_user_sales",
      ],
      limited_access: [],
    },
  },
  AutoPosting: {
    page_level: {
      full_access: [
        "platform_admin",
        "platform_user",
        "application_admin",
      ],
      no_access: [
        "application_user",
        "account_admin",
        "department_manager_clinical",
        "department_manager_clerical",
        "department_manager_sales",
        "department_manager_billing",
        "department_user_billing",
        "department_user_sales",
        "department_user_clinical",
        "department_user_clerical",
        "multi_department_manager_sales",
        "multi_department_manager_clinical",
        "multi_department_manager_clerical",
        "multi_department_manager_billing",
      ],
      limited_access: [],
    },
    export: {
      full_access: [
        "platform_admin",
        "application_admin",
      ],
      no_access: [
        "platform_user",
        "application_user",
        "account_admin",
        "department_manager_clinical",
        "department_manager_clerical",
        "department_manager_sales",
        "department_user_sales",
        "department_user_clinical",
        "department_user_clerical",
        "multi_department_manager_sales",
        "multi_department_manager_clinical",
        "multi_department_manager_clerical",
        "department_manager_billing",
        "department_user_billing",
        "multi_department_manager_billing",
      ],
      limited_access: [],
    },
    comments: {
      full_access: [
        "platform_admin",
        "application_admin",
      ],
      no_access: [
        "account_admin",
        "platform_user",
        "application_user",
        "department_manager_billing",
        "department_user_billing",
        "multi_department_manager_billing",
        "department_manager_clinical",
        "department_manager_clerical",
        "department_manager_sales",
        "department_user_sales",
        "department_user_clinical",
        "department_user_clerical",
        "multi_department_manager_sales",
        "multi_department_manager_clinical",
        "multi_department_manager_clerical",
      ],
      limited_access: [],
    },
  },

  BlankHipps: {
    page_level: {
      full_access: [
        "platform_admin",
        "platform_user",
        "application_admin",
        "application_user",
        "department_manager_clinical",
        "department_manager_clerical",
        "multi_department_manager_clinical",
        "multi_department_manager_clerical",
      ],
      no_access: [
        "account_admin",
        "department_manager_sales",
        "department_manager_billing",
        "department_user_billing",
        "department_user_sales",
        "department_user_clinical",
        "department_user_clerical",
        "multi_department_manager_billing",
        "multi_department_manager_sales",
      ],
      limited_access: [],
    },
    export: {
      full_access: [
        "platform_admin",
        "application_admin",
        "department_manager_clinical",
        "department_manager_clerical",
        "multi_department_manager_clinical",
        "multi_department_manager_clerical",
      ],
      no_access: [
        "platform_user",
        "application_user",
        "account_admin",
        "department_manager_sales",
        "department_manager_billing",
        "department_user_billing",
        "department_user_sales",
        "department_user_clinical",
        "department_user_clerical",
        "multi_department_manager_billing",
        "multi_department_manager_sales",
      ],
      limited_access: [],
    },
    callToAction: {
      full_access: [
        "platform_admin",
        "application_admin",
        "application_user",
        "department_manager_clinical",
        "department_manager_clerical",
        "multi_department_manager_clinical",
        "multi_department_manager_clerical",
      ],

      no_access: [
        "platform_user",
        "account_admin",
        "department_manager_billing",
        "department_manager_sales",
        "department_user_billing",
        "department_user_sales",
        "department_user_clinical",
        "department_user_clerical",
        "multi_department_manager_billing",
        "multi_department_manager_sales",
      ],
      limited_access: [],
    },
  },

  Census: {
    page_level: {
      full_access: [
        "platform_admin",
        "platform_user",
        "application_admin",
        "application_user",
        "department_manager_sales",
        "department_manager_clinical",
        "department_manager_clerical",
        "department_manager_billing",
        "department_user_sales",
        "department_user_clinical",
        "department_user_clerical",
        "department_user_billing",
        "multi_department_manager_sales",
        "multi_department_manager_clinical",
        "multi_department_manager_clerical",
        "multi_department_manager_billing",
      ],
      no_access: ["account_admin"],
      limited_access: [],
    },
    export: {
      full_access: [
        "platform_admin",
        "application_admin",
        "department_manager_sales",
        "department_manager_clinical",
        "department_manager_clerical",
        "department_manager_billing",
        "department_user_sales",
        "department_user_clinical",
        "department_user_clerical",
        "department_user_billing",
        "multi_department_manager_sales",
        "multi_department_manager_clinical",
        "multi_department_manager_clerical",
        "multi_department_manager_billing",
      ],
      no_access: ["platform_user", "application_user", "account_admin"],
      limited_access: [],
    },
  },

  DataPlayGround: {
    page_level: {
      full_access: ["platform_admin", "platform_user", "application_admin"],
      no_access: [
        "application_user",
        "account_admin",
        "department_manager_billing",
        "department_manager_sales",
        "department_manager_clinical",
        "department_manager_clerical",
        "multi_department_manager_billing",
        "multi_department_manager_sales",
        "multi_department_manager_clinical",
        "multi_department_manager_clerical",
        "department_user_billing",
        "department_user_sales",
        "department_user_clinical",
        "department_user_clerical",
      ],
      limited_access: [],
    },
    export: {
      full_access: [
        "platform_admin",
        "application_admin",
      ],
      no_access: [
        "application_user",
        "account_admin",
        "department_manager_billing",
        "department_manager_sales",
        "department_manager_clinical",
        "department_manager_clerical",
        "department_user_billing",
        "department_user_sales",
        "department_user_clinical",
        "department_user_clerical",
        "multi_department_manager_billing",
        "multi_department_manager_sales",
        "multi_department_manager_clinical",
        "multi_department_manager_clerical",
      ],
      limited_access: [],
    },
  },

  DenialClaims: {
    page_level: {
      full_access: [
        "application_user",
        "platform_admin",
        "platform_user",
        "application_admin",
        "department_manager_billing",
        "department_user_billing",
        "multi_department_manager_billing",
      ],
      no_access: [
        "account_admin",
        "department_manager_clinical",
        "department_manager_clerical",
        "department_manager_sales",
        "department_user_sales",
        "department_user_clinical",
        "department_user_clerical",
        "multi_department_manager_sales",
        "multi_department_manager_clinical",
        "multi_department_manager_clerical",
      ],
      limited_access: [],
    },
    export: {
      full_access: [
        "platform_admin",
        "application_admin",
        "department_manager_billing",
        "department_user_billing",
        "multi_department_manager_billing",
      ],
      no_access: [
        "platform_user",
        "application_user",
        "account_admin",
        "department_manager_clinical",
        "department_manager_clerical",
        "department_manager_sales",
        "department_user_sales",
        "department_user_clinical",
        "department_user_clerical",
        "multi_department_manager_sales",
        "multi_department_manager_clinical",
        "multi_department_manager_clerical",
      ],
      limited_access: [],
    },
    comments: {
      full_access: [
        "platform_admin",
        "platform_user",
        "application_user",
        "application_admin",
        "department_manager_billing",
        "department_user_billing",
        "multi_department_manager_billing",
      ],
      no_access: [
        "account_admin",
        "department_manager_clinical",
        "department_manager_clerical",
        "department_manager_sales",
        "department_user_sales",
        "department_user_clinical",
        "department_user_clerical",
        "multi_department_manager_sales",
        "multi_department_manager_clinical",
        "multi_department_manager_clerical",
      ],
      limited_access: ["platform_user", "application_user"],
    },
  },

  DischargePlanning: {
    page_level: {
      full_access: [
        "platform_admin",
        "platform_user",
        "application_admin",
        "application_user",
        "department_manager_clinical",
        "department_manager_clerical",
        "department_user_clinical",
        "department_user_clerical",
        "multi_department_manager_clinical",
        "multi_department_manager_clerical",
      ],
      no_access: [
        "account_admin",
        "department_manager_sales",
        "department_manager_billing",
        "department_user_billing",
        "department_user_sales",
        "multi_department_manager_billing",
        "multi_department_manager_sales",
      ],
      limited_access: [],
    },
    export: {
      full_access: [
        "platform_admin",
        "application_admin",
        "department_manager_clinical",
        "department_manager_clerical",
        "department_user_clinical",
        "department_user_clerical",
        "multi_department_manager_clinical",
        "multi_department_manager_clerical",
      ],
      no_access: [
        "platform_user",
        "application_user",
        "account_admin",
        "department_manager_sales",
        "department_manager_billing",
        "department_user_billing",
        "department_user_sales",
        "multi_department_manager_billing",
        "multi_department_manager_sales",
      ],
      limited_access: [],
    },
  },

  EpisodeVisitAuthSummary: {
    page_level: {
      full_access: [
        "platform_admin",
        "platform_user",
        "application_admin",
        "application_user",
        "department_manager_billing",
        "department_user_billing",
        "multi_department_manager_billing",
      ],
      no_access: [
        "account_admin",
        "department_manager_sales",
        "department_manager_clinical",
        "department_manager_clerical",
        "department_user_sales",
        "department_user_clinical",
        "department_user_clerical",
        "multi_department_manager_sales",
        "multi_department_manager_clinical",
        "multi_department_manager_clerical",
      ],
      limited_access: [],
    },
    export: {
      full_access: [
        "platform_admin",
        "application_admin",
        "department_manager_billing",
        "multi_department_manager_billing",
      ],
      no_access: [
        "platform_user",
        "application_user",
        "account_admin",
        "department_manager_sales",
        "department_manager_clinical",
        "department_manager_clerical",
        "department_user_billing",
        "department_user_sales",
        "department_user_clinical",
        "department_user_clerical",
        "multi_department_manager_sales",
        "multi_department_manager_clinical",
        "multi_department_manager_clerical",
      ],
      limited_access: [],
    },
  },

  IncorrectMRNs: {
    page_level: {
      full_access: [
        "platform_admin",
        "platform_user",
        "application_admin",
        "application_user",
        "department_manager_clinical",
        "department_manager_clerical",
        "department_user_clinical",
        "department_user_clerical",
        "multi_department_manager_clinical",
        "multi_department_manager_clerical",
      ],
      no_access: [
        "account_admin",
        "department_manager_sales",
        "department_manager_billing",
        "department_user_billing",
        "department_user_sales",
        "multi_department_manager_billing",
        "multi_department_manager_sales",
      ],
      limited_access: [],
    },
    export: {
      full_access: [
        "platform_admin",
        "application_admin",
        "department_manager_clinical",
        "department_manager_clerical",
        "department_user_clinical",
        "department_user_clerical",
        "multi_department_manager_clinical",
        "multi_department_manager_clerical",
      ],
      no_access: [
        "platform_user",
        "application_user",
        "account_admin",
        "department_manager_sales",
        "department_manager_billing",
        "department_user_billing",
        "department_user_sales",
        "multi_department_manager_billing",
        "multi_department_manager_sales",
      ],
      limited_access: [],
    },
  },

  LupaRisk: {
    page_level: {
      full_access: [
        "platform_admin",
        "platform_user",
        "application_admin",
        "application_user",
        "department_manager_billing",
        "department_manager_clinical",
        "department_manager_clerical",
        "department_user_clinical",
        "department_user_clerical",
        "department_user_billing",
        "multi_department_manager_clinical",
        "multi_department_manager_clerical",
        "multi_department_manager_billing",
      ],
      no_access: [
        "account_admin",
        "department_manager_sales",
        "department_user_sales",
        "multi_department_manager_sales",
      ],
      limited_access: [],
    },
    export: {
      full_access: [
        "platform_admin",
        "application_admin",
        "department_manager_clinical",
        "department_manager_clerical",
        "department_manager_billing",
        "department_user_clinical",
        "department_user_clerical",
        "department_user_billing",
        "multi_department_manager_clinical",
        "multi_department_manager_clerical",
        "multi_department_manager_billing",
      ],
      no_access: [
        "platform_user",
        "application_user",
        "account_admin",
        "department_manager_sales",
        "department_user_sales",
        "multi_department_manager_sales",
      ],
      limited_access: [],
    },
  },

  MissingAuthorizations: {
    page_level: {
      full_access: [
        "platform_admin",
        "platform_user",
        "application_admin",
        "application_user",
        "department_manager_billing",
        "department_manager_clinical",
        "department_user_billing",
        "multi_department_manager_billing",
        "multi_department_manager_clinical",
      ],
      no_access: [
        "account_admin",
        "department_manager_sales",
        "department_manager_clerical",
        "department_user_sales",
        "department_user_clinical",
        "department_user_clerical",
        "multi_department_manager_sales",
        "multi_department_manager_clerical",
      ],
      limited_access: [],
    },
    export: {
      full_access: [
        "platform_admin",
        "application_admin",
        "department_manager_billing",
        "department_manager_clinical",
        "department_user_billing",
        "multi_department_manager_billing",
        "multi_department_manager_clinical",
      ],
      no_access: [
        "platform_user",
        "application_user",
        "account_admin",
        "department_manager_sales",
        "department_manager_clerical",
        "department_user_sales",
        "department_user_clinical",
        "department_user_clerical",
        "multi_department_manager_sales",
        "multi_department_manager_clerical",
      ],
      limited_access: [],
    },
  },

  Orders: {
    page_level: {
      full_access: [
        "platform_admin",
        "platform_user",
        "application_admin",
        "application_user",
        "department_manager_billing",
        "department_manager_sales",
        "department_manager_clinical",
        "department_manager_clerical",
        "department_user_billing",
        "department_user_sales",
        "department_user_clerical",
        "multi_department_manager_billing",
        "multi_department_manager_sales",
        "multi_department_manager_clinical",
        "multi_department_manager_clerical",
      ],
      no_access: ["account_admin", "department_user_clinical"],
      limited_access: [],
    },
    export: {
      full_access: [
        "platform_admin",
        "application_admin",
        "department_manager_billing",
        "department_manager_sales",
        "department_manager_clinical",
        "department_manager_clerical",
        "department_user_billing",
        "department_user_sales",
        "department_user_clerical",
        "multi_department_manager_billing",
        "multi_department_manager_sales",
        "multi_department_manager_clinical",
        "multi_department_manager_clerical",
      ],
      no_access: [
        "platform_user",
        "application_user",
        "account_admin",
        "department_user_clinical",
      ],
      limited_access: [],
    },
    comments: {
      full_access: [
        "platform_admin",
        "platform_user",
        "application_admin",
        "application_user",
        "department_manager_billing",
        "department_manager_sales",
        "department_manager_clinical",
        "department_manager_clerical",
        "department_user_billing",
        "department_user_sales",
        "department_user_clerical",
        "multi_department_manager_billing",
        "multi_department_manager_sales",
        "multi_department_manager_clinical",
        "multi_department_manager_clerical",
      ],
      no_access: ["account_admin", "department_user_clinical"],
      limited_access: ["platform_user"],
    },
  },

  PatientResponsibility: {
    page_level: {
      full_access: [
        "platform_admin",
        "platform_user",
        "application_admin",
        "application_user",
        "department_manager_billing",
        "department_user_billing",
        "multi_department_manager_billing",
      ],
      no_access: [
        "account_admin",
        "department_manager_sales",
        "department_manager_clinical",
        "department_manager_clerical",
        "department_user_sales",
        "department_user_clinical",
        "department_user_clerical",
        "multi_department_manager_sales",
        "multi_department_manager_clinical",
        "multi_department_manager_clerical",
      ],
      limited_access: [],
    },
    export: {
      full_access: [
        "platform_admin",
        "application_admin",
        "department_manager_billing",
        "department_user_billing",
        "multi_department_manager_billing",
      ],
      no_access: [
        "platform_user",
        "application_user",
        "account_admin",
        "department_manager_sales",
        "department_manager_clinical",
        "department_manager_clerical",
        "department_user_sales",
        "department_user_clinical",
        "department_user_clerical",
        "multi_department_manager_sales",
        "multi_department_manager_clinical",
        "multi_department_manager_clerical",
      ],
      limited_access: [],
    },
    view_update_functionality: {
      full_access: [
        "platform_admin",
        "application_admin",
        "application_user",
        "department_manager_billing",
        "department_user_billing",
        "multi_department_manager_billing",
      ],
      no_access: [
        "platform_user",
        "account_admin",
        "department_manager_sales",
        "department_manager_clinical",
        "department_manager_clerical",
        "department_user_sales",
        "department_user_clinical",
        "department_user_clerical",
        "multi_department_manager_sales",
        "multi_department_manager_clinical",
        "multi_department_manager_clerical",
      ],
      limited_access: [],
    },
    comments: {
      full_access: [
        "platform_admin",
        "application_admin",
        "application_user",
        "department_manager_billing",
        "department_user_billing",
        "multi_department_manager_billing",
      ],
      no_access: [
        "platform_user",
        "account_admin",
        "department_manager_sales",
        "department_manager_clinical",
        "department_manager_clerical",
        "department_user_sales",
        "department_user_clinical",
        "department_user_clerical",
        "multi_department_manager_sales",
        "multi_department_manager_clinical",
        "multi_department_manager_clerical",
      ],
      limited_access: [],
    },
  },

  PatientEligibility: {
    page_level: {
      full_access: [
        "platform_admin",
        "platform_user",
        "application_admin",
        "application_user",
        "department_manager_billing",
        "department_manager_sales",
        "department_manager_clinical",
        "department_manager_clerical",
        "department_user_billing",
        "department_user_sales",
        "department_user_clinical",
        "department_user_clerical",
        "multi_department_manager_billing",
        "multi_department_manager_sales",
        "multi_department_manager_clinical",
        "multi_department_manager_clerical",
      ],
      no_access: ["account_admin"],
      limited_access: [],
    },
    export: {
      full_access: [
        "platform_admin",
        "application_admin",
        "department_manager_billing",
        "department_manager_sales",
        "department_manager_clinical",
        "department_manager_clerical",
        "department_user_billing",
        "department_user_sales",
        "department_user_clinical",
        "department_user_clerical",
        "multi_department_manager_billing",
        "multi_department_manager_sales",
        "multi_department_manager_clinical",
        "multi_department_manager_clerical",
      ],
      no_access: ["platform_user", "application_user", "account_admin"],
      limited_access: [],
    },
    view_functionality: {
      full_access: [
        "platform_admin",
        "platform_user",
        "application_admin",
        "application_user",
        "department_manager_billing",
        "department_manager_sales",
        "department_manager_clinical",
        "department_manager_clerical",
        "department_user_billing",
        "department_user_sales",
        "department_user_clinical",
        "department_user_clerical",
        "multi_department_manager_billing",
        "multi_department_manager_sales",
        "multi_department_manager_clinical",
        "multi_department_manager_clerical",
      ],
      no_access: ["account_admin"],
      limited_access: [],
    },
    eligibility_check: {
      full_access: [
        "platform_admin",
        "platform_user",
        "application_admin",
        "application_user",
        "department_manager_billing",
        "department_manager_sales",
        "department_manager_clinical",
        "department_manager_clerical",
        "department_user_billing",
        "department_user_sales",
        "department_user_clinical",
        "department_user_clerical",
        "multi_department_manager_billing",
        "multi_department_manager_sales",
        "multi_department_manager_clinical",
        "multi_department_manager_clerical",
      ],
      no_access: ["account_admin"],
      limited_access: [],
    },
  },

  PastDueVisits: {
    page_level: {
      full_access: [
        "platform_admin",
        "platform_user",
        "application_admin",
        "application_user",
        "department_manager_billing",
        "department_manager_clinical",
        "department_manager_clerical",
        "department_user_clinical",
        "department_user_clerical",
        "multi_department_manager_billing",
        "multi_department_manager_clinical",
        "multi_department_manager_clerical",
      ],

      no_access: [
        "account_admin",
        "department_manager_sales",
        "department_user_billing",
        "department_user_sales",
        "multi_department_manager_sales",
      ],
      limited_access: [],
    },
    export: {
      full_access: [
        "platform_admin",
        "application_admin",
        "department_manager_billing",
        "department_manager_clinical",
        "department_manager_clerical",
        "department_user_clinical",
        "department_user_clerical",
        "multi_department_manager_billing",
        "multi_department_manager_clinical",
        "multi_department_manager_clerical",
      ],
      no_access: [
        "platform_user",
        "application_user",
        "account_admin",
        "department_manager_sales",
        "department_user_billing",
        "department_user_sales",
        "multi_department_manager_sales",
      ],
      limited_access: [],
    },
    callToAction: {
      full_access: [
        "platform_admin",
        "application_admin",
        "application_user",
        "department_manager_billing",
        "department_manager_clinical",
        "department_manager_clerical",
        "department_user_clinical",
        "department_user_clerical",
        "multi_department_manager_billing",
        "multi_department_manager_clinical",
        "multi_department_manager_clerical",
      ],

      no_access: [
        "platform_user",
        "account_admin",
        "department_manager_sales",
        "department_user_billing",
        "department_user_sales",
        "multi_department_manager_sales",
      ],
      limited_access: [],
    },
  },

  PCCDashboard: {
    page_level: {
      full_access: [
        "platform_admin",
        "platform_user",
        "application_admin",
        "application_user",
        "department_manager_clinical",
        "department_manager_clerical",
        "department_user_clinical",
        "department_user_clerical",
        "multi_department_manager_clinical",
        "multi_department_manager_clerical",
      ],

      no_access: [
        "account_admin",
        "department_manager_billing",
        "department_manager_sales",
        "department_user_billing",
        "department_user_sales",
        "multi_department_manager_billing",
        "multi_department_manager_sales",
      ],
      limited_access: [],
    },
    export: {
      full_access: [
        "platform_admin",
        "application_admin",
        "department_manager_clinical",
        "department_manager_clerical",
        "department_user_clinical",
        "department_user_clerical",
        "multi_department_manager_clinical",
        "multi_department_manager_clerical",
      ],
      no_access: [
        "platform_user",
        "application_user",
        "account_admin",
        "department_manager_sales",
        "department_manager_billing",
        "department_user_billing",
        "department_user_sales",
        "multi_department_manager_billing",
        "multi_department_manager_sales",
      ],
      limited_access: [],
    },
    callToAction: {
      full_access: [
        "platform_admin",
        "platform_user",
        "application_admin",
        "application_user",
        "department_manager_clinical",
        "department_manager_clerical",
        "department_user_clinical",
        "department_user_clerical",
        "multi_department_manager_clinical",
        "multi_department_manager_clerical",
      ],

      no_access: [
        "account_admin",
        "department_manager_billing",
        "department_manager_sales",
        "department_user_billing",
        "department_user_sales",
        "multi_department_manager_billing",
        "multi_department_manager_sales",
      ],
      limited_access: [],
    },
  },

  PDGMManagement: {
    page_level: {
      full_access: [
        "platform_admin",
        "platform_user",
        "application_admin",
        "application_user",
        "department_manager_clinical",
        "department_manager_clerical",
        "department_user_clinical",
        "department_user_clerical",
        "multi_department_manager_clinical",
        "multi_department_manager_clerical",
      ],
      no_access: [
        "account_admin",
        "department_manager_billing",
        "department_manager_sales",
        "department_user_billing",
        "department_user_sales",
        "multi_department_manager_billing",
        "multi_department_manager_sales",
      ],
      limited_access: [],
    },
    export: {
      full_access: [
        "platform_admin",
        "application_admin",
        "department_manager_clinical",
        "department_manager_clerical",
        "department_user_clinical",
        "department_user_clerical",
        "multi_department_manager_clinical",
        "multi_department_manager_clerical",
      ],
      no_access: [
        "platform_user",
        "application_user",
        "account_admin",
        "department_manager_billing",
        "department_manager_sales",
        "department_user_billing",
        "department_user_sales",
        "multi_department_manager_billing",
        "multi_department_manager_sales",
      ],
      limited_access: [],
    },
    comments: {
      full_access: [
        "platform_admin",
        "platform_user",
        "application_admin",
        "application_user",
        "department_manager_clinical",
        "department_manager_clerical",
        "department_user_clinical",
        "department_user_clerical",
        "multi_department_manager_clinical",
        "multi_department_manager_clerical",
      ],
      no_access: [
        "account_admin",
        "department_manager_billing",
        "department_manager_sales",
        "department_user_billing",
        "department_user_sales",
        "multi_department_manager_billing",
        "multi_department_manager_sales",
      ],
      limited_access: ["platform_user"],
    },
  },

  Productivity: {
    page_level: {
      full_access: [
        "platform_admin",
        "platform_user",
        "application_admin",
        "application_user",
        "department_manager_clinical",
        "department_manager_clerical",
        "department_user_clinical",
        "department_user_clerical",
        "multi_department_manager_clinical",
        "multi_department_manager_clerical",
      ],
      no_access: [
        "account_admin",
        "department_manager_billing",
        "department_manager_sales",
        "department_user_billing",
        "department_user_sales",
        "multi_department_manager_billing",
        "multi_department_manager_sales",
      ],
      limited_access: [],
    },
    export: {
      full_access: [
        "platform_admin",
        "application_admin",
        "department_manager_clinical",
        "department_manager_clerical",
        "department_user_clinical",
        "department_user_clerical",
        "multi_department_manager_clinical",
        "multi_department_manager_clerical",
      ],
      no_access: [
        "platform_user",
        "application_user",
        "account_admin",
        "department_manager_billing",
        "department_manager_sales",
        "department_user_billing",
        "department_user_sales",
        "multi_department_manager_billing",
        "multi_department_manager_sales",
      ],
      limited_access: [],
    },
  },

  PerVisitManagement: {
    page_level: {
      full_access: [
        "platform_admin",
        "platform_user",
        "application_admin",
        "application_user",
        "department_manager_clinical",
        "department_manager_clerical",
        "department_user_clinical",
        "department_user_clerical",
        "multi_department_manager_clinical",
        "multi_department_manager_clerical",
      ],
      no_access: [
        "account_admin",
        "department_manager_billing",
        "department_manager_sales",
        "department_user_billing",
        "department_user_sales",
        "multi_department_manager_billing",
        "multi_department_manager_sales",
      ],
      limited_access: [],
    },
    export: {
      full_access: [
        "platform_admin",
        "application_admin",
        "department_manager_clinical",
        "department_manager_clerical",
        "department_user_clinical",
        "department_user_clerical",
        "multi_department_manager_clinical",
        "multi_department_manager_clerical",
      ],
      no_access: [
        "platform_user",
        "application_user",
        "account_admin",
        "department_manager_billing",
        "department_manager_sales",
        "department_user_billing",
        "department_user_sales",
        "multi_department_manager_billing",
        "multi_department_manager_sales",
      ],
      limited_access: [],
    },
    comments: {
      full_access: [
        "platform_admin",
        "platform_user",
        "application_admin",
        "application_user",
        "department_manager_clinical",
        "department_manager_clerical",
        "department_user_clinical",
        "department_user_clerical",
        "multi_department_manager_clinical",
        "multi_department_manager_clerical",
      ],
      no_access: [
        "account_admin",
        "department_manager_billing",
        "department_manager_sales",
        "department_user_billing",
        "department_user_sales",
        "multi_department_manager_billing",
        "multi_department_manager_sales",
      ],
      limited_access: ["platform_user"],
    },
  },

  ProviderProfitability: {
    page_level: {
      full_access: [
        "application_user",
        "platform_admin",
        "platform_user",
        "application_admin",
        "department_manager_billing",
        "department_user_billing",
        "multi_department_manager_billing",
      ],
      no_access: [
        "account_admin",
        "department_manager_clinical",
        "department_manager_clerical",
        "department_manager_sales",
        "department_user_sales",
        "department_user_clinical",
        "department_user_clerical",
        "multi_department_manager_sales",
        "multi_department_manager_clinical",
        "multi_department_manager_clerical",
      ],
      limited_access: [],
    },
    export: {
      full_access: [
        "platform_admin",
        "application_admin",
        "department_manager_billing",
        "department_user_billing",
        "multi_department_manager_billing",
      ],
      no_access: [
        "platform_user",
        "application_user",
        "account_admin",
        "department_manager_clinical",
        "department_manager_clerical",
        "department_manager_sales",
        "department_user_sales",
        "department_user_clinical",
        "department_user_clerical",
        "multi_department_manager_sales",
        "multi_department_manager_clinical",
        "multi_department_manager_clerical",
      ],
      limited_access: [],
    },
    comments: {
      full_access: [
        "platform_admin",
        "platform_user",
        "application_user",
        "application_admin",
        "department_manager_billing",
        "department_user_billing",
        "multi_department_manager_billing",
      ],
      no_access: [
        "account_admin",
        "department_manager_clinical",
        "department_manager_clerical",
        "department_manager_sales",
        "department_user_sales",
        "department_user_clinical",
        "department_user_clerical",
        "multi_department_manager_sales",
        "multi_department_manager_clinical",
        "multi_department_manager_clerical",
      ],
      limited_access: ["platform_user", "application_user"],
    },
  },

  Referrals: {
    page_level: {
      full_access: [
        "platform_admin",
        "platform_user",
        "application_admin",
        "application_user",
        "department_manager_sales",
        "department_manager_clerical",
        "department_user_sales",
        "department_user_clerical",
        "multi_department_manager_sales",
        "multi_department_manager_clerical",
        "department_manager_billing",
        "multi_department_manager_billing"
      ],
      no_access: [
        "account_admin",
        "department_manager_clinical",
        "department_user_billing",
        "department_user_clinical",
        "multi_department_manager_clinical",
      ],
      limited_access: [],
    },
    export: {
      full_access: [
        "platform_admin",
        "application_admin",
        "department_manager_sales",
        "department_manager_clerical",
        "department_user_sales",
        "department_user_clerical",
        "multi_department_manager_sales",
        "multi_department_manager_clerical",
      ],
      no_access: [
        "platform_user",
        "application_user",
        "account_admin",
        "department_manager_billing",
        "department_manager_clinical",
        "department_user_billing",
        "department_user_clinical",
        "multi_department_manager_billing",
      ],
      limited_access: [],
    },
  },

  ReviewChoiceDemonstration: {
    page_level: {
      full_access: [
        "platform_admin",
        "platform_user",
        "application_admin",
        "application_user",
        "department_manager_billing",
        "department_manager_clinical",
        "department_manager_clerical",
        "department_user_billing",
        "department_user_clinical",
        "department_user_clerical",
        "multi_department_manager_billing",
        "multi_department_manager_clinical",
        "multi_department_manager_clerical",
      ],
      no_access: [
        "account_admin",
        "department_manager_sales",
        "department_user_sales",
        "multi_department_manager_sales",
      ],
      limited_access: [],
    },
    export: {
      full_access: [
        "platform_admin",
        "application_admin",
        "department_manager_billing",
        "department_manager_clinical",
        "department_manager_clerical",
        "department_user_billing",
        "department_user_clinical",
        "department_user_clerical",
        "multi_department_manager_billing",
        "multi_department_manager_clinical",
        "multi_department_manager_clerical",
      ],
      no_access: [
        "platform_user",
        "application_user",
        "account_admin",
        "department_manager_sales",
        "department_user_sales",
        "multi_department_manager_sales",
      ],
      limited_access: [],
    },
    callToAction: {
      full_access: [
        "platform_admin",
        "application_admin",
        "department_manager_billing",
        "department_manager_clinical",
        "department_manager_clerical",
        "department_user_billing",
        "department_user_clinical",
        "department_user_clerical",
        "multi_department_manager_billing",
        "multi_department_manager_clinical",
        "multi_department_manager_clerical",
      ],
      no_access: [
        "platform_user",
        "application_user",
        "account_admin",
        "department_manager_sales",
        "department_user_sales",
        "multi_department_manager_sales",
      ],
      limited_access: [],
    },
    comments: {
      full_access: [
        "platform_admin",
        "platform_user",
        "application_admin",
        "application_user",
        "department_manager_billing",
        "department_manager_clinical",
        "department_manager_clerical",
        "department_user_billing",
        "department_user_clinical",
        "department_user_clerical",
        "multi_department_manager_billing",
        "multi_department_manager_clinical",
        "multi_department_manager_clerical",
      ],
      no_access: [
        "account_admin",
        "department_manager_sales",
        "department_user_sales",
        "multi_department_manager_sales",
      ],
      limited_access: ["platform_user"],
    },
  },

  ScheduleDeviation: {
    page_level: {
      full_access: [
        "platform_admin",
        "platform_user",
        "application_admin",
        "application_user",
        "department_manager_clinical",
        "department_manager_clerical",
        "department_user_clinical",
        "department_user_clerical",
        "multi_department_manager_clinical",
        "multi_department_manager_clerical",
      ],
      no_access: [
        "account_admin",
        "department_manager_sales",
        "department_manager_billing",
        "department_user_billing",
        "department_user_sales",
        "multi_department_manager_billing",
        "multi_department_manager_sales",
      ],
      limited_access: [],
    },
    export: {
      full_access: [
        "platform_admin",
        "application_admin",
        "department_manager_clinical",
        "department_manager_clerical",
        "multi_department_manager_clinical",
        "multi_department_manager_clerical",
      ],
      no_access: [
        "platform_user",
        "application_user",
        "account_admin",
        "department_manager_sales",
        "department_manager_billing",
        "department_user_billing",
        "department_user_sales",
        "department_user_clinical",
        "department_user_clerical",
        "multi_department_manager_billing",
        "multi_department_manager_sales",
      ],
      limited_access: [],
    },
  },

  SyncHistory: {
    page_level: {
      full_access: [
        "application_user",
        "account_admin",
        "platform_admin",
        "platform_user",
        "application_admin",
        "department_manager_clinical",
        "department_manager_clerical",
        "department_manager_sales",
        "department_manager_billing",
        "department_user_billing",
        "department_user_sales",
        "department_user_clinical",
        "department_user_clerical",
        "multi_department_manager_billing",
        "multi_department_manager_sales",
        "multi_department_manager_clinical",
        "multi_department_manager_clerical",
      ],
      no_access: [],
      limited_access: [],
    },
    export: {
      full_access: ["platform_user", "platform_admin", "application_admin"],
      no_access: [
        "account_admin",
        "application_user",
        "department_manager_clinical",
        "department_manager_clerical",
        "department_manager_sales",
        "department_manager_billing",
        "department_user_billing",
        "department_user_sales",
        "department_user_clinical",
        "department_user_clerical",
        "multi_department_manager_billing",
        "multi_department_manager_sales",
        "multi_department_manager_clinical",
        "multi_department_manager_clerical",
      ],
      limited_access: [],
    },
  },

  Users: {
    page_level: {
      full_access: [
        "application_user",
        "account_admin",
        "platform_admin",
        "platform_user",
        "application_admin",
      ],
      no_access: [
        "department_manager_clinical",
        "department_manager_clerical",
        "department_manager_sales",
        "department_manager_billing",
        "department_user_billing",
        "department_user_sales",
        "department_user_clinical",
        "department_user_clerical",
        "multi_department_manager_billing",
        "multi_department_manager_sales",
        "multi_department_manager_clinical",
        "multi_department_manager_clerical",
      ],
      limited_access: [],
    },
    export: {
      full_access: ["account_admin", "platform_admin", "application_admin"],
      no_access: [
        "platform_user",
        "application_user",
        "department_manager_clinical",
        "department_manager_clerical",
        "department_manager_sales",
        "department_manager_billing",
        "department_user_billing",
        "department_user_sales",
        "department_user_clinical",
        "department_user_clerical",
        "multi_department_manager_billing",
        "multi_department_manager_sales",
        "multi_department_manager_clinical",
        "multi_department_manager_clerical",
      ],
      limited_access: [],
    },
    update_user_functionality: {
      full_access: [
        "account_admin",
        "platform_admin",
        "platform_user",
        "application_admin",
      ],
      no_access: [
        "application_user",
        "department_manager_clinical",
        "department_manager_clerical",
        "department_manager_sales",
        "department_manager_billing",
        "department_user_billing",
        "department_user_sales",
        "department_user_clinical",
        "department_user_clerical",
        "multi_department_manager_billing",
        "multi_department_manager_sales",
        "multi_department_manager_clinical",
        "multi_department_manager_clerical",
      ],
      limited_access: [],
    },
  },

  ReleaseHistory: {
    page_level: {
      full_access: [
        "application_user",
        "account_admin",
        "platform_admin",
        "platform_user",
        "application_admin",
        "department_manager_clinical",
        "department_manager_clerical",
        "department_manager_sales",
        "department_manager_billing",
        "department_user_billing",
        "department_user_sales",
        "department_user_clinical",
        "department_user_clerical",
        "multi_department_manager_billing",
        "multi_department_manager_sales",
        "multi_department_manager_clinical",
        "multi_department_manager_clerical",
      ],
      no_access: [],
      limited_access: [],
    },
    add_new_release_history: {
      full_access: ["platform_admin", "platform_user", "application_admin"],
      no_access: [
        "application_user",
        "account_admin",
        "department_manager_clinical",
        "department_manager_clerical",
        "department_manager_sales",
        "department_manager_billing",
        "department_user_billing",
        "department_user_sales",
        "department_user_clinical",
        "department_user_clerical",
        "multi_department_manager_billing",
        "multi_department_manager_sales",
        "multi_department_manager_clinical",
        "multi_department_manager_clerical",
      ],
      limited_access: [],
    },
    update_release_history: {
      full_access: ["platform_admin", "platform_user", "application_admin"],
      no_access: [
        "application_user",
        "account_admin",
        "department_manager_clinical",
        "department_manager_clerical",
        "department_manager_sales",
        "department_manager_billing",
        "department_user_billing",
        "department_user_sales",
        "department_user_clinical",
        "department_user_clerical",
        "multi_department_manager_billing",
        "multi_department_manager_sales",
        "multi_department_manager_clinical",
        "multi_department_manager_clerical",
      ],
      limited_access: [],
    },
  },

  Help: {
    page_level: {
      full_access: [
        "application_user",
        "account_admin",
        "platform_admin",
        "platform_user",
        "application_admin",
        "department_manager_clinical",
        "department_manager_clerical",
        "department_manager_sales",
        "department_manager_billing",
        "department_user_billing",
        "department_user_sales",
        "department_user_clinical",
        "department_user_clerical",
        "multi_department_manager_billing",
        "multi_department_manager_sales",
        "multi_department_manager_clinical",
        "multi_department_manager_clerical",
      ],
      no_access: [],
      limited_access: [],
    },
    update_help: {
      full_access: ["platform_admin", "platform_user", "application_admin"],
      no_access: [
        "application_user",
        "account_admin",
        "department_manager_clinical",
        "department_manager_clerical",
        "department_manager_sales",
        "department_manager_billing",
        "department_user_billing",
        "department_user_sales",
        "department_user_clinical",
        "department_user_clerical",
        "multi_department_manager_billing",
        "multi_department_manager_sales",
        "multi_department_manager_clinical",
        "multi_department_manager_clerical",
      ],
      limited_access: [],
    },
  },

  ReportBug: {
    page_level: {
      full_access: [
        "application_user",
        "account_admin",
        "platform_admin",
        "platform_user",
        "application_admin",
        "department_manager_clinical",
        "department_manager_clerical",
        "department_manager_sales",
        "department_manager_billing",
        "department_user_billing",
        "department_user_sales",
        "department_user_clinical",
        "department_user_clerical",
        "multi_department_manager_billing",
        "multi_department_manager_sales",
        "multi_department_manager_clinical",
        "multi_department_manager_clerical",
      ],
      no_access: [],
      limited_access: [],
    },
  },

  Settings: {
    page_level: {
      full_access: [
        "platform_admin",
        "platform_user",
        "application_admin",
        "department_manager_clinical",
        "department_manager_clerical",
        "department_manager_sales",
        "department_manager_billing",
        "department_user_billing",
        "department_user_clinical",
        "department_user_clerical",
        "multi_department_manager_billing",
        "multi_department_manager_sales",
        "multi_department_manager_clinical",
        "multi_department_manager_clerical",
      ],
      no_access: ["application_user", "account_admin", "department_user_sales"],
      limited_access: [],
    },
    emr_connectivity: {
      full_access: ["platform_admin", "platform_user", "application_admin"],
      no_access: [
        "application_user",
        "account_admin",
        "department_user_sales",
        "department_manager_clinical",
        "department_manager_clerical",
        "department_manager_sales",
        "department_manager_billing",
        "department_user_billing",
        "department_user_clinical",
        "department_user_clerical",
        "multi_department_manager_billing",
        "multi_department_manager_sales",
        "multi_department_manager_clinical",
        "multi_department_manager_clerical",
      ],
      limited_access: [],
    },
    clearing_house_connectivity: {
      full_access: [
        "platform_admin",
        "platform_user",
        "application_admin",
        "department_user_clinical",
      ],
      no_access: [
        "application_user",
        "account_admin",
        "department_user_sales",
        "department_manager_clinical",
        "department_manager_clerical",
        "department_manager_sales",
        "department_manager_billing",
        "department_user_billing",
        "department_user_clerical",
        "multi_department_manager_billing",
        "multi_department_manager_sales",
        "multi_department_manager_clinical",
        "multi_department_manager_clerical",
      ],
      limited_access: [],
    },
    clearing_house_connectivity_CTA: {
      full_access: ["platform_admin", "platform_user", "application_admin"],
      no_access: [
        "department_user_clinical",
        "application_user",
        "account_admin",
        "department_user_sales",
        "department_manager_clinical",
        "department_manager_clerical",
        "department_manager_sales",
        "department_manager_billing",
        "department_user_billing",
        "department_user_clerical",
        "multi_department_manager_billing",
        "multi_department_manager_sales",
        "multi_department_manager_clinical",
        "multi_department_manager_clerical",
      ],
      limited_access: [],
    },
    other_settings: {
      full_access: ["platform_admin", "platform_user", "application_admin"],
      no_access: [
        "application_user",
        "account_admin",
        "department_user_sales",
        "department_manager_clinical",
        "department_manager_clerical",
        "department_manager_sales",
        "department_manager_billing",
        "department_user_billing",
        "department_user_clinical",
        "department_user_clerical",
        "multi_department_manager_billing",
        "multi_department_manager_sales",
        "multi_department_manager_clinical",
        "multi_department_manager_clerical",
      ],
      limited_access: [],
    },

    credentials: {
      full_access: ["platform_admin"],
      no_access: [
        "platform_user",
        "application_admin",
        "application_user",
        "account_admin",
        "department_user_sales",
        "department_manager_clinical",
        "department_manager_clerical",
        "department_manager_sales",
        "department_manager_billing",
        "department_user_billing",
        "department_user_clinical",
        "department_user_clerical",
        "multi_department_manager_billing",
        "multi_department_manager_sales",
        "multi_department_manager_clinical",
        "multi_department_manager_clerical",
      ],
      limited_access: [],
    },
    user_activity: {
      full_access: [
        "platform_admin",
        "application_admin"
      ],
      no_access: [
        "platform_user",
        "application_user",
        "account_admin",
        "department_user_sales",
        "department_manager_clinical",
        "department_manager_clerical",
        "department_manager_sales",
        "department_manager_billing",
        "department_user_billing",
        "department_user_clinical",
        "department_user_clerical",
        "multi_department_manager_billing",
        "multi_department_manager_sales",
        "multi_department_manager_clinical",
        "multi_department_manager_clerical",
      ],
      limited_access: [],
    },
    authorizations: {
      full_access: ["platform_admin"],
      no_access: [
        "platform_user",
        "application_admin",
        "application_user",
        "account_admin",
        "department_user_sales",
        "department_manager_clinical",
        "department_manager_clerical",
        "department_manager_sales",
        "department_manager_billing",
        "department_user_billing",
        "department_user_clinical",
        "department_user_clerical",
        "multi_department_manager_billing",
        "multi_department_manager_sales",
        "multi_department_manager_clinical",
        "multi_department_manager_clerical",
      ],
      limited_access: [],
    },
    configurations: {
      full_access: ["platform_admin", "platform_user", "application_admin"],
      no_access: [
        "application_user",
        "account_admin",
        "department_user_sales",
        "department_manager_clinical",
        "department_manager_clerical",
        "department_manager_sales",
        "department_manager_billing",
        "department_user_billing",
        "department_user_clinical",
        "department_user_clerical",
        "multi_department_manager_billing",
        "multi_department_manager_sales",
        "multi_department_manager_clinical",
        "multi_department_manager_clerical",
      ],
      limited_access: [],
    },

    //Primarydetails = pd
    //Employment Status
    pd_employment_status: {
      full_access: [
        "platform_admin",
        "platform_user",
        "application_admin",
        "department_manager_clinical",
        "department_manager_clerical",
        "department_manager_billing",
        "department_user_billing",
        "department_user_clerical",
        "multi_department_manager_billing",
        "multi_department_manager_clinical",
        "multi_department_manager_clerical",
      ],
      no_access: [
        "application_user",
        "account_admin",
        "department_user_clinical",
        "department_manager_sales",
        "department_user_sales",
        "multi_department_manager_sales",
      ],
      limited_access: [],
    },
    pd_employment_status_crud: {
      full_access: [
        "platform_admin",
        "platform_user",
        "application_admin",
        "department_manager_clinical",
        "department_manager_clerical",
        "department_manager_billing",
        "multi_department_manager_billing",
        "multi_department_manager_clinical",
        "multi_department_manager_clerical",
      ],
      no_access: [
        "application_user",
        "account_admin",
        "department_user_clinical",
        "department_user_clerical",
        "department_user_billing",
        "department_manager_sales",
        "department_user_sales",
        "multi_department_manager_sales",
      ],
      limited_access: [],
    },
    pd_employment_status_export: {
      full_access: [
        "platform_admin",
        "platform_user",
        "application_admin",
        "department_manager_clinical",
        "department_manager_clerical",
        "department_manager_billing",
        "multi_department_manager_billing",
        "multi_department_manager_clinical",
        "multi_department_manager_clerical",
      ],
      no_access: [
        "application_user",
        "account_admin",
        "department_user_clinical",
        "department_user_clerical",
        "department_user_billing",
        "department_manager_sales",
        "department_user_sales",
        "multi_department_manager_sales",
      ],
      limited_access: [],
    },

    //Order Status
    pd_order_status: {
      full_access: [
        "platform_admin",
        "platform_user",
        "application_admin",
        "department_manager_billing",
        "department_user_billing",
        "multi_department_manager_billing",
      ],
      no_access: [
        "application_user",
        "account_admin",
        "department_user_clinical",
        "department_manager_clinical",
        "department_manager_clerical",
        "department_manager_sales",
        "department_user_sales",
        "department_user_clerical",
        "multi_department_manager_clinical",
        "multi_department_manager_clerical",
        "multi_department_manager_sales",
      ],
      limited_access: [],
    },
    pd_order_status_crud: {
      full_access: [
        "platform_admin",
        "platform_user",
        "application_admin",
        "department_manager_billing",
        "multi_department_manager_billing",
      ],
      no_access: [
        "application_user",
        "account_admin",
        "department_user_clinical",
        "department_user_billing",
        "department_manager_clinical",
        "department_manager_clerical",
        "department_manager_sales",
        "department_user_sales",
        "department_user_clerical",
        "multi_department_manager_clinical",
        "multi_department_manager_clerical",
        "multi_department_manager_sales",
      ],
      limited_access: [],
    },
    pd_order_status_export: {
      full_access: [
        "platform_admin",
        "platform_user",
        "application_admin",
        "department_manager_billing",
        "multi_department_manager_billing",
      ],
      no_access: [
        "application_user",
        "account_admin",
        "department_user_billing",
        "department_user_clinical",
        "department_manager_clinical",
        "department_manager_clerical",
        "department_manager_sales",
        "department_user_sales",
        "department_user_clerical",
        "multi_department_manager_clinical",
        "multi_department_manager_clerical",
        "multi_department_manager_sales",
      ],
      limited_access: [],
    },

    //Comment Category
    pd_comment_category: {
      full_access: [
        "platform_admin",
        "platform_user",
        "application_admin",
        "department_manager_billing",
        "department_manager_clinical",
        "department_manager_clerical",
        "department_manager_sales",
        "department_user_billing",
        "department_user_clinical",
        "department_user_clerical",
        "multi_department_manager_billing",
        "multi_department_manager_sales",
        "multi_department_manager_clinical",
        "multi_department_manager_clerical",
      ],
      no_access: ["application_user", "account_admin", "department_user_sales"],
      limited_access: [],
    },
    pd_comment_category_crud: {
      full_access: [
        "platform_admin",
        "platform_user",
        "application_admin",
        "department_manager_billing",
        "department_manager_clinical",
        "department_manager_clerical",
        "department_manager_sales",
        "multi_department_manager_billing",
        "multi_department_manager_sales",
        "multi_department_manager_clinical",
        "multi_department_manager_clerical",
      ],
      no_access: [
        "application_user",
        "account_admin",
        "department_user_clinical",
        "department_user_sales",
        "department_user_clerical",
        "department_user_billing",
      ],
      limited_access: [],
    },
    pd_comment_category_export: {
      full_access: [
        "platform_admin",
        "platform_user",
        "application_admin",
        "department_manager_billing",
        "department_manager_clinical",
        "department_manager_clerical",
        "department_manager_sales",
        "multi_department_manager_billing",
        "multi_department_manager_sales",
        "multi_department_manager_clinical",
        "multi_department_manager_clerical",
      ],
      no_access: [
        "application_user",
        "account_admin",
        "department_user_clinical",
        "department_user_sales",
        "department_user_clerical",
        "department_user_billing",
      ],
      limited_access: [],
    },

    //Payor Details
    pd_payor_details: {
      full_access: [
        "platform_admin",
        "platform_user",
        "application_admin",
        "department_manager_billing",
        "department_user_billing",
        "multi_department_manager_billing",
      ],
      no_access: [
        "application_user",
        "account_admin",
        "department_user_clinical",
        "department_manager_clinical",
        "department_manager_clerical",
        "department_manager_sales",
        "department_user_sales",
        "department_user_clerical",
        "multi_department_manager_clinical",
        "multi_department_manager_clerical",
        "multi_department_manager_sales",
      ],
      limited_access: [],
    },
    pd_payor_details_crud: {
      full_access: [
        "platform_admin",
        "platform_user",
        "application_admin",
        "department_manager_billing",
        "multi_department_manager_billing",
      ],
      no_access: [
        "application_user",
        "account_admin",
        "department_user_clinical",
        "department_user_billing",
        "department_manager_clinical",
        "department_manager_clerical",
        "department_manager_sales",
        "department_user_sales",
        "department_user_clerical",
        "multi_department_manager_clinical",
        "multi_department_manager_clerical",
        "multi_department_manager_sales",
      ],
      limited_access: [],
    },
    pd_payor_details_export: {
      full_access: [
        "platform_admin",
        "platform_user",
        "application_admin",
        "department_manager_billing",
        "multi_department_manager_billing",
      ],
      no_access: [
        "application_user",
        "account_admin",
        "department_user_billing",
        "department_user_clinical",
        "department_manager_clinical",
        "department_manager_clerical",
        "department_manager_sales",
        "department_user_sales",
        "department_user_clerical",
        "multi_department_manager_clinical",
        "multi_department_manager_clerical",
        "multi_department_manager_sales",
      ],
      limited_access: [],
    },

    //Physician
    pd_physician: {
      full_access: [
        "platform_admin",
        "platform_user",
        "application_admin",
        "department_manager_billing",
        "department_manager_clinical",
        "department_manager_clerical",
        "department_user_billing",
        "department_user_clinical",
        "department_user_clerical",
        "multi_department_manager_billing",
        "multi_department_manager_clinical",
        "multi_department_manager_clerical",
      ],
      no_access: [
        "application_user",
        "account_admin",
        "department_manager_sales",
        "department_user_sales",
        "multi_department_manager_sales",
      ],
      limited_access: [],
    },
    pd_physician_crud: {
      full_access: [
        "platform_admin",
        "platform_user",
        "application_admin",
        "department_manager_billing",
        "department_manager_clinical",
        "department_manager_clerical",
        "multi_department_manager_billing",
        "multi_department_manager_clinical",
        "multi_department_manager_clerical",
      ],
      no_access: [
        "application_user",
        "account_admin",
        "department_manager_sales",
        "department_user_sales",
        "department_user_billing",
        "department_user_clinical",
        "department_user_clerical",
        "multi_department_manager_sales",
      ],
      limited_access: [],
    },
    pd_physician_export: {
      full_access: [
        "platform_admin",
        "platform_user",
        "application_admin",
        "department_manager_billing",
        "department_manager_clinical",
        "department_manager_clerical",
        "multi_department_manager_billing",
        "multi_department_manager_clinical",
        "multi_department_manager_clerical",
      ],
      no_access: [
        "application_user",
        "account_admin",
        "department_manager_sales",
        "department_user_sales",
        "department_user_billing",
        "department_user_clinical",
        "department_user_clerical",
        "multi_department_manager_sales",
      ],
      limited_access: [],
    },

    //Payor Allowable
    pd_payor_allowable: {
      full_access: [
        "platform_admin",
        "platform_user",
        "application_admin",
        "department_manager_billing",
        "department_user_billing",
        "multi_department_manager_billing",
      ],
      no_access: [
        "application_user",
        "account_admin",
        "department_user_clinical",
        "department_manager_clinical",
        "department_manager_clerical",
        "department_manager_sales",
        "department_user_sales",
        "department_user_clerical",
        "multi_department_manager_clinical",
        "multi_department_manager_clerical",
        "multi_department_manager_sales",
      ],
      limited_access: [],
    },
    pd_payor_allowable_crud: {
      full_access: [
        "platform_admin",
        "platform_user",
        "application_admin",
        "department_manager_billing",
        "multi_department_manager_billing",
      ],
      no_access: [
        "application_user",
        "account_admin",
        "department_user_clinical",
        "department_user_billing",
        "department_manager_clinical",
        "department_manager_clerical",
        "department_manager_sales",
        "department_user_sales",
        "department_user_clerical",
        "multi_department_manager_clinical",
        "multi_department_manager_clerical",
        "multi_department_manager_sales",
      ],
      limited_access: [],
    },
    pd_payor_allowable_export: {
      full_access: [
        "platform_admin",
        "platform_user",
        "application_admin",
        "department_manager_billing",
        "multi_department_manager_billing",
      ],
      no_access: [
        "application_user",
        "account_admin",
        "department_user_billing",
        "department_user_clinical",
        "department_manager_clinical",
        "department_manager_clerical",
        "department_manager_sales",
        "department_user_sales",
        "department_user_clerical",
        "multi_department_manager_clinical",
        "multi_department_manager_clerical",
        "multi_department_manager_sales",
      ],
      limited_access: [],
    },

    //Insurance to payment
    pd_insurance_to_payments: {
      full_access: [
        "platform_admin",
        "platform_user",
        "application_admin",
        "department_manager_billing",
        "department_user_billing",
        "multi_department_manager_billing",
      ],
      no_access: [
        "application_user",
        "account_admin",
        "department_user_clinical",
        "department_manager_clinical",
        "department_manager_clerical",
        "department_manager_sales",
        "department_user_sales",
        "department_user_clerical",
        "multi_department_manager_clinical",
        "multi_department_manager_clerical",
        "multi_department_manager_sales",
      ],
      limited_access: [],
    },
    pd_insurance_to_payments_crud: {
      full_access: [
        "platform_admin",
        "platform_user",
        "application_admin",
        "department_manager_billing",
        "multi_department_manager_billing",
      ],
      no_access: [
        "application_user",
        "account_admin",
        "department_user_clinical",
        "department_user_billing",
        "department_manager_clinical",
        "department_manager_clerical",
        "department_manager_sales",
        "department_user_sales",
        "department_user_clerical",
        "multi_department_manager_clinical",
        "multi_department_manager_clerical",
        "multi_department_manager_sales",
      ],
      limited_access: [],
    },
    pd_insurance_to_payments_export: {
      full_access: [
        "platform_admin",
        "platform_user",
        "application_admin",
        "department_manager_billing",
        "multi_department_manager_billing",
      ],
      no_access: [
        "application_user",
        "account_admin",
        "department_user_billing",
        "department_user_clinical",
        "department_manager_clinical",
        "department_manager_clerical",
        "department_manager_sales",
        "department_user_sales",
        "department_user_clerical",
        "multi_department_manager_clinical",
        "multi_department_manager_clerical",
        "multi_department_manager_sales",
      ],
      limited_access: [],
    },

    //Productivity Visit Types
    pd_prod_visit_types: {
      full_access: [
        "platform_admin",
        "platform_user",
        "application_admin",
        "department_manager_billing",
        "department_manager_clinical",
        "department_manager_clerical",
        "department_user_billing",
        "department_user_clinical",
        "department_user_clerical",
        "multi_department_manager_billing",
        "multi_department_manager_clinical",
        "multi_department_manager_clerical",
      ],
      no_access: [
        "application_user",
        "account_admin",
        "department_manager_sales",
        "department_user_sales",
        "multi_department_manager_sales",
      ],
      limited_access: [],
    },
    pd_prod_visit_types_crud: {
      full_access: [
        "platform_admin",
        "platform_user",
        "application_admin",
        "department_manager_billing",
        "department_manager_clinical",
        "department_manager_clerical",
        "multi_department_manager_billing",
        "multi_department_manager_clinical",
        "multi_department_manager_clerical",
      ],
      no_access: [
        "application_user",
        "account_admin",
        "department_manager_sales",
        "department_user_sales",
        "department_user_billing",
        "department_user_clinical",
        "department_user_clerical",
        "multi_department_manager_sales",
      ],
      limited_access: [],
    },
    pd_prod_visit_types_export: {
      full_access: [
        "platform_admin",
        "platform_user",
        "application_admin",
        "department_manager_billing",
        "department_manager_clinical",
        "department_manager_clerical",
        "multi_department_manager_billing",
        "multi_department_manager_clinical",
        "multi_department_manager_clerical",
      ],
      no_access: [
        "application_user",
        "account_admin",
        "department_manager_sales",
        "department_user_sales",
        "department_user_billing",
        "department_user_clinical",
        "department_user_clerical",
        "multi_department_manager_sales",
      ],
      limited_access: [],
    },

    //Tooltip Info
    tooltip_info: {
      full_access: ["platform_admin", "platform_user", "application_admin"],
      no_access: [
        "application_user",
        "account_admin",
        "department_manager_billing",
        "department_manager_sales",
        "department_manager_clinical",
        "department_manager_clerical",
        "department_user_billing",
        "department_user_sales",
        "department_user_clinical",
        "department_user_clerical",
        "multi_department_manager_billing",
        "multi_department_manager_sales",
        "multi_department_manager_clinical",
        "multi_department_manager_clerical",
      ],
      limited_access: [],
    },
    tooltip_info_crud: {
      full_access: ["platform_admin", "platform_user", "application_admin"],
      no_access: [
        "application_user",
        "account_admin",
        "department_manager_billing",
        "department_manager_sales",
        "department_manager_clinical",
        "department_manager_clerical",
        "department_user_sales",
        "department_user_billing",
        "department_user_clinical",
        "department_user_clerical",
        "multi_department_manager_billing",
        "multi_department_manager_sales",
        "multi_department_manager_clinical",
        "multi_department_manager_clerical",
      ],
      limited_access: [],
    },
    tooltip_info_export: {
      full_access: ["platform_admin", "platform_user", "application_admin"],
      no_access: [
        "application_user",
        "account_admin",
        "department_manager_billing",
        "department_manager_sales",
        "department_manager_clinical",
        "department_manager_clerical",
        "department_user_sales",
        "department_user_billing",
        "department_user_clinical",
        "department_user_clerical",
        "multi_department_manager_billing",
        "multi_department_manager_sales",
        "multi_department_manager_clinical",
        "multi_department_manager_clerical",
      ],
      limited_access: [],
    },
    staff_details: {
      full_access: ["platform_admin", "application_admin"],
      no_access: ["platform_user",
        "application_user",
        "account_admin",
        "department_manager_billing",
        "department_manager_sales",
        "department_manager_clinical",
        "department_manager_clerical",
        "department_user_billing",
        "department_user_sales",
        "department_user_clinical",
        "department_user_clerical",
        "multi_department_manager_billing",
        "multi_department_manager_sales",
        "multi_department_manager_clinical",
        "multi_department_manager_clerical",
      ],
      limited_access: [],
    },
  },
  ViewTreeStructure: {
    page_level: {
      full_access: ["platform_admin", "platform_user", "application_admin"],
      no_access: [
        "application_user",
        "account_admin",
        "department_manager_billing",
        "department_manager_sales",
        "department_manager_clinical",
        "department_manager_clerical",
        "multi_department_manager_billing",
        "multi_department_manager_sales",
        "multi_department_manager_clinical",
        "multi_department_manager_clerical",
        "department_user_billing",
        "department_user_sales",
        "department_user_clinical",
        "department_user_clerical",
      ],
      limited_access: [],
    },
    export: {
      full_access: [
        "platform_admin",
        "application_admin",
      ],
      no_access: [
        "application_user",
        "account_admin",
        "department_manager_billing",
        "department_manager_sales",
        "department_manager_clinical",
        "department_manager_clerical",
        "department_user_billing",
        "department_user_sales",
        "department_user_clinical",
        "department_user_clerical",
        "multi_department_manager_billing",
        "multi_department_manager_sales",
        "multi_department_manager_clinical",
        "multi_department_manager_clerical",
      ],
      limited_access: [],
    },
  },

  // billing screens

  BillingScreen: {
    page_level: {
      full_access: [
        "platform_admin",
        "platform_user",
        "application_admin",
        "department_manager_billing",
        "department_user_billing",
        "multi_department_manager_billing",
      ],
      no_access: [
        "application_user",
        "account_admin",
        "department_manager_clinical",
        "department_manager_clerical",
        "department_manager_sales",
        "department_user_sales",
        "department_user_clinical",
        "department_user_clerical",
        "multi_department_manager_sales",
        "multi_department_manager_clinical",
        "multi_department_manager_clerical",
      ],
      limited_access: [],
    },
    export: {
      full_access: [
        "platform_admin",
        "application_admin",
        "department_manager_billing",
        "department_user_billing",
        "multi_department_manager_billing",
      ],
      no_access: [
        "platform_user",
        "application_user",
        "account_admin",
        "department_manager_clinical",
        "department_manager_clerical",
        "department_manager_sales",
        "department_user_sales",
        "department_user_clinical",
        "department_user_clerical",
        "multi_department_manager_sales",
        "multi_department_manager_clinical",
        "multi_department_manager_clerical",
      ],
      limited_access: ["department_user_billing"],
    },
    comments: {
      full_access: [
        "platform_admin",
        "platform_user",
        "application_admin",
        "department_manager_billing",
        "department_user_billing",
        "multi_department_manager_billing",
      ],
      no_access: [
        "application_user",
        "account_admin",
        "department_manager_clinical",
        "department_manager_clerical",
        "department_manager_sales",
        "department_user_sales",
        "department_user_clinical",
        "department_user_clerical",
        "multi_department_manager_sales",
        "multi_department_manager_clinical",
        "multi_department_manager_clerical",
      ],
      limited_access: ["platform_user"],
    },
  },

  AgedAr: {
    page_level: {
      full_access: [
        "platform_admin",
        "platform_user",
        "application_admin",
        "department_manager_billing",
        "department_user_billing",
        "multi_department_manager_billing",
      ],
      no_access: [
        "application_user",
        "account_admin",
        "department_manager_clinical",
        "department_manager_clerical",
        "department_manager_sales",
        "department_user_sales",
        "department_user_clinical",
        "department_user_clerical",
        "multi_department_manager_sales",
        "multi_department_manager_clinical",
        "multi_department_manager_clerical",
      ],
      limited_access: [],
    },
    export: {
      full_access: [
        "platform_admin",
        "application_admin",
        "department_manager_billing",
        "department_user_billing",
        "multi_department_manager_billing",
      ],
      no_access: [
        "platform_user",
        "application_user",
        "account_admin",
        "department_manager_clinical",
        "department_manager_clerical",
        "department_manager_sales",
        "department_user_sales",
        "department_user_clinical",
        "department_user_clerical",
        "multi_department_manager_sales",
        "multi_department_manager_clinical",
        "multi_department_manager_clerical",
      ],
      limited_access: ["department_user_billing"],
    },
  },

  ToBeBilled: {
    page_level: {
      full_access: [
        "platform_admin",
        "platform_user",
        "application_admin",
        "department_manager_billing",
        "department_user_billing",
        "multi_department_manager_billing",
      ],
      no_access: [
        "application_user",
        "account_admin",
        "department_manager_sales",
        "department_manager_clinical",
        "department_manager_clerical",
        "department_user_sales",
        "department_user_clinical",
        "department_user_clerical",
        "multi_department_manager_sales",
        "multi_department_manager_clinical",
        "multi_department_manager_clerical",
      ],
      limited_access: [],
    },
    export: {
      full_access: [
        "platform_admin",
        "application_admin",
        "department_manager_billing",
        "department_user_billing",
        "multi_department_manager_billing",
      ],
      no_access: [
        "platform_user",
        "application_user",
        "account_admin",
        "department_manager_sales",
        "department_manager_clinical",
        "department_manager_clerical",
        "department_user_sales",
        "department_user_clinical",
        "department_user_clerical",
        "multi_department_manager_sales",
        "multi_department_manager_clinical",
        "multi_department_manager_clerical",
      ],
      limited_access: ["department_user_billing"],
    },
  },

  BillingBatchvsRemittance: {
    page_level: {
      full_access: [
        "platform_admin",
        "platform_user",
        "application_admin",
        "department_manager_billing",
        "department_user_billing",
        "multi_department_manager_billing",
      ],
      no_access: [
        "application_user",
        "account_admin",
        "department_manager_sales",
        "department_manager_clinical",
        "department_manager_clerical",
        "department_user_sales",
        "department_user_clinical",
        "department_user_clerical",
        "multi_department_manager_sales",
        "multi_department_manager_clinical",
        "multi_department_manager_clerical",
      ],
      limited_access: [],
    },
    export: {
      full_access: [
        "platform_admin",
        "application_admin",
        "department_manager_billing",
        "department_user_billing",
        "multi_department_manager_billing",
      ],
      no_access: [
        "application_user",
        "account_admin",
        "department_manager_clinical",
        "department_manager_clerical",
        "department_manager_sales",
        "department_user_sales",
        "department_user_clinical",
        "department_user_clerical",
        "multi_department_manager_sales",
        "multi_department_manager_clinical",
        "multi_department_manager_clerical",
      ],
      limited_access: [],
    },
  },

  BillingRemittances: {
    page_level: {
      full_access: [
        "platform_admin",
        "platform_user",
        "application_admin",
        "department_manager_billing",
        "department_user_billing",
        "multi_department_manager_billing",
      ],
      no_access: [
        "application_user",
        "account_admin",
        "department_manager_sales",
        "department_manager_clinical",
        "department_manager_clerical",
        "department_user_sales",
        "department_user_clinical",
        "department_user_clerical",
        "multi_department_manager_sales",
        "multi_department_manager_clinical",
        "multi_department_manager_clerical",
      ],
      limited_access: [],
    },
    export: {
      full_access: [
        "platform_admin",
        "application_admin",
        "department_manager_billing",
        "department_user_billing",
        "multi_department_manager_billing",
      ],
      no_access: [
        "platform_user",
        "application_user",
        "account_admin",
        "department_manager_clinical",
        "department_manager_clerical",
        "department_manager_sales",
        "department_user_sales",
        "department_user_clinical",
        "department_user_clerical",
        "multi_department_manager_sales",
        "multi_department_manager_clinical",
        "multi_department_manager_clerical",
      ],
      limited_access: [],
    },
  },

  BillingBatchReport: {
    page_level: {
      full_access: [
        "platform_admin",
        "platform_user",
        "application_admin",
        "department_manager_billing",
        "department_user_billing",
        "multi_department_manager_billing",
      ],
      no_access: [
        "application_user",
        "account_admin",
        "department_manager_sales",
        "department_manager_clinical",
        "department_manager_clerical",
        "department_user_sales",
        "department_user_clinical",
        "department_user_clerical",
        "multi_department_manager_sales",
        "multi_department_manager_clinical",
        "multi_department_manager_clerical",
      ],
      limited_access: [],
    },
    export: {
      full_access: [
        "platform_admin",
        "application_admin",
        "department_manager_billing",
        "department_user_billing",
        "multi_department_manager_billing",
      ],
      no_access: [
        "platform_user",
        "application_user",
        "account_admin",
        "department_manager_clinical",
        "department_manager_clerical",
        "department_manager_sales",
        "department_user_sales",
        "department_user_clinical",
        "department_user_clerical",
        "multi_department_manager_sales",
        "multi_department_manager_clinical",
        "multi_department_manager_clerical",
      ],
      limited_access: [],
    },
  },

  // billing screens
};

export const IsAuthorized = (screenName, sectionName) => {
  let user = JSON.parse(localStorage.getItem("hnts_user"));
  let loggedInRoles = user?.consolidated_role_and_dep
    ? user?.consolidated_role_and_dep
    : [];
  let typeOfAccess = "";
  // current loggedIn user
  // let unauthorizedUser = user?.consolidated_role_and_dep ? user?.consolidated_role_and_dep.filter((item, index) => (
  //     authInfo[screen]?.no_access.includes(item)
  // )) : [];
  if (sectionName) {
    for (let singleRole in loggedInRoles) {
      if (
        authInfo[screenName]?.[sectionName]?.full_access?.includes(
          loggedInRoles[singleRole]
        )
      ) {
        return (typeOfAccess = "full_access");
      } else if (
        authInfo[screenName]?.[sectionName]?.no_access.includes(
          loggedInRoles[singleRole]
        )
      ) {
        typeOfAccess = "no_access";
      } else if (
        authInfo[screenName]?.[sectionName]?.limited_access?.includes(
          loggedInRoles[singleRole]
        )
      ) {
        typeOfAccess = "limited_access";
      }

      // console.log(loggedInRoles[singleRole], screenName, sectionName, typeOfAccess);
    }
  } else {
    for (let singleRole in loggedInRoles) {
      if (
        authInfo[screenName]?.full_access?.includes(loggedInRoles[singleRole])
      ) {
        return (typeOfAccess = "full_access");
      } else if (
        authInfo[screenName]?.no_access.includes(loggedInRoles[singleRole])
      ) {
        typeOfAccess = "no_access";
      }
    }
  }
  // console.log("AccessType", typeOfAccess)
  let isAccessible = true;
  if (typeOfAccess === "full_access") {
    isAccessible = true;
  } else {
    isAccessible = false;
  }
  // console.log("isAccessible", isAccessible)
  return isAccessible;
};

export const limitedAccessFunction = (screenName, sectionName) => {
  let user = JSON.parse(localStorage.getItem("hnts_user"));
  let loggedInRoles = user?.consolidated_role_and_dep
    ? user?.consolidated_role_and_dep
    : [];
  for (let singleRole in loggedInRoles) {
    if (
      authInfo[screenName][sectionName]["limited_access"]?.includes(
        loggedInRoles[singleRole]
      )
    ) {
      return true;
    } else {
      return false;
    }
  }
};
